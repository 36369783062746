<template>
  <div id="gangting-baseInfo">
    <img :src="weather.bg" alt="" />
    <p class="redWarning" v-if="isRedWarning">
      警告：车位{{ idleLots === 0 ? "已满位" : "即将满位" }}，请及时处理
    </p>
    <div>
      <div class="remainingParkingSpaces">
        剩余车位：
        <ul>
          <li v-for="(item, index) of remainingParkingSpaces" :key="index">
            {{ item }}
          </li>
        </ul>
        个

        <span
          class="revise"
          v-if="buttonList.includes('gangting-bigScreen-remain-revise')"
        >
          <img
            @click="revise"
            class="reviseIcon"
            :src="require('@/assets/images/weather/edit.png')"
            alt=""
          />
          <span @click="revise">修改</span>
        </span>
      </div>
      <div class="remainingParkingSpaces" style="margin-top: 5px">
        总车位：
        <ul>
          <li v-for="(item, index) of totalParkingSpaces" :key="index">
            {{ item }}
          </li>
        </ul>
        个
      </div>
    </div>
    <div class="weatherForecast">
      <div class="top">
        <div class="situation">
          <img :src="weather.img" alt="" />
          <div>
            <p>{{ temperature }} °C</p>
            <p>{{ weather.name }}</p>
          </div>
        </div>
        <div class="air">
          <img :src="require('@/assets/images/weather/air.png')" alt="" />
          <span>空气</span>
          <i>{{ quality }}</i>
        </div>
      </div>
      <ul class="bottom">
        <li>
          <span>{{ currentDate }}</span>
        </li>
        <li>
          <img :src="require('@/assets/images/weather/windSpeed.png')" alt="" />
          <span>{{ speed }}</span>
        </li>
        <li>
          <img :src="require('@/assets/images/weather/humidity.png')" alt="" />
          <span>{{ humidity }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      idleLots: 0,
      totalLots: 0,
      remainingParkingSpaces: [],
      totalParkingSpaces: ["-"],
      prefix: "",
      currentDate: "",
      temperature: "-",
      weather: "",
      quality: "",
      humidity: "",
      speed: "",
      dateTimer: null,
      weatherTimer: null,
      isRedWarning: false,
    };
  },
  computed: {
    parkingLotId() {
      return this.$store.state.GangTing.parkingLotId;
    },
    socketInfo() {
      return this.$store.state.socket.info;
    },
  },
  watch: {
    parkingLotId: {
      handler(id) {
        id && this.getRemainingParkingSpaces();
        id && this.init();
      },
      immediate: true,
    },
    socketInfo(info) {
      if (info.tag === "0413") {
        this.isRedWarning =
          (
            ((this.totalLots - info.data.remain) / this.totalLots) *
            100
          ).toFixed(2) >= 95;

        this.idleLots = info.data.remain;
        this.remainingParkingSpaces = String(
          info.data.remain < 0 ? 0 : info.data.remain
        ).split("");
      }
    },
  },
  created() {
    this.dateTimer = setInterval(() => {
      this.currentDate = this.$moment().format("YYYY/MM/DD");
    }, 1000);
  },
  destroyed() {
    clearInterval(this.dateTimer);
    clearTimeout(this.weatherTimer);
  },
  methods: {
    async getRemainingParkingSpaces() {
      try {
        let res = await this.$http.get(`/parking/detail/${this.parkingLotId}`);
        if (res.code === 0) {
          this.totalLots = res.data.totalLots;
          this.isRedWarning =
            (
              ((this.totalLots - res.data.idleLots) / this.totalLots) *
              100
            ).toFixed(2) >= 95;
          this.idleLots = res.data.idleLots;
          this.remainingParkingSpaces = String(
            res.data.idleLots < 0 ? 0 : res.data.idleLots
          ).split("");
          this.totalParkingSpaces = String(res.data.totalLots).split("");
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error(err);
      }
    },
    revise() {
      this.$prompt("", "修改剩余车位", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /^\d+$/,
        inputErrorMessage: "请输入正确数量",
        inputPlaceholder: "请输入修改的数值",
      }).then(async ({ value }) => {
        let res = await this.$http.post("/parking/edit", {
          parkingLotId: this.parkingLotId,
          idleLots: value,
        });
        if (res.code === 0) {
          this.$message.success("修改成功");
          this.getRemainingParkingSpaces();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    weatherChange(skycon) {
      switch (skycon) {
        case "CLEAR_DAY":
          return {
            img: require("@/assets/images/weather/CLEAR_DAY.png"),
            bg: require("@/assets/images/weather/CLEAR_DAY_bg.png"),
            name: "晴",
          };
        case "CLEAR_NIGHT":
          return {
            img: require("@/assets/images/weather/CLEAR_DAY.png"),
            bg: require("@/assets/images/weather/CLEAR_DAY_bg.png"),
            name: "晴",
          };
        case "PARTLY_CLOUDY_DAY":
          return {
            img: require("@/assets/images/weather/PARTLY_CLOUDY_DAY.png"),
            bg: require("@/assets/images/weather/PARTLY_CLOUDY_DAY_bg.png"),
            name: "多云",
          };
        case "PARTLY_CLOUDY_NIGHT":
          return {
            img: require("@/assets/images/weather/PARTLY_CLOUDY_DAY.png"),
            bg: require("@/assets/images/weather/PARTLY_CLOUDY_DAY_bg.png"),
            name: "多云",
          };
        case "CLOUDY":
          return {
            img: require("@/assets/images/weather/PARTLY_CLOUDY_DAY.png"),
            bg: require("@/assets/images/weather/PARTLY_CLOUDY_DAY_bg.png"),
            name: "阴",
          };
        case "LIGHT_HAZE":
          return {
            img: require("@/assets/images/weather/FOG.png"),
            bg: require("@/assets/images/weather/FOG_bg.png"),
            name: "轻度雾霾",
          };
        case "MODERATE_HAZE":
          return {
            img: require("@/assets/images/weather/FOG.png"),
            bg: require("@/assets/images/weather/FOG_bg.png"),
            name: "中度雾霾",
          };
        case "HEAVY_HAZE":
          return {
            img: require("@/assets/images/weather/FOG.png"),
            bg: require("@/assets/images/weather/FOG_bg.png"),
            name: "重度雾霾",
          };
        case "LIGHT_RAIN":
          return {
            img: require("@/assets/images/weather/RAIN.png"),
            bg: require("@/assets/images/weather/RAIN_bg.png"),
            name: "小雨",
          };
        case "MODERATE_RAIN":
          return {
            img: require("@/assets/images/weather/RAIN.png"),
            bg: require("@/assets/images/weather/RAIN_bg.png"),
            name: "中雨",
          };
        case "HEAVY_RAIN":
          return {
            img: require("@/assets/images/weather/RAIN.png"),
            bg: require("@/assets/images/weather/RAIN_bg.png"),
            name: "大雨",
          };
        case "STORM_RAIN":
          return {
            img: require("@/assets/images/weather/RAIN.png"),
            bg: require("@/assets/images/weather/RAIN_bg.png"),
            name: "暴雨",
          };
        case "FOG":
          return {
            img: require("@/assets/images/weather/FOG.png"),
            bg: require("@/assets/images/weather/FOG_bg.png"),
            name: "雾",
          };
        case "LIGHT_SNOW":
          return {
            img: require("@/assets/images/weather/SNOW.png"),
            bg: require("@/assets/images/weather/SNOW_bg.png"),
            name: "小雪",
          };
        case "MODERATE_SNOW":
          return {
            img: require("@/assets/images/weather/SNOW.png"),
            bg: require("@/assets/images/weather/SNOW_bg.png"),
            name: "中雪",
          };
        case "HEAVY_SNOW":
          return {
            img: require("@/assets/images/weather/SNOW.png"),
            bg: require("@/assets/images/weather/SNOW_bg.png"),
            name: "大雪",
          };
        case "STORM_SNOW":
          return {
            img: require("@/assets/images/weather/SNOW.png"),
            bg: require("@/assets/images/weather/SNOW_bg.png"),
            name: "暴雪",
          };
        case "DUST":
          return {
            img: require("@/assets/images/weather/PARTLY_CLOUDY_DAY.png"),
            bg: require("@/assets/images/weather/PARTLY_CLOUDY_DAY_bg.png"),
            name: "浮尘",
          };
        case "SAND":
          return {
            img: require("@/assets/images/weather/PARTLY_CLOUDY_DAY.png"),
            bg: require("@/assets/images/weather/PARTLY_CLOUDY_DAY_bg.png"),
            name: "沙尘",
          };
        case "WIND":
          return {
            img: require("@/assets/images/weather/PARTLY_CLOUDY_DAY.png"),
            bg: require("@/assets/images/weather/PARTLY_CLOUDY_DAY_bg.png"),
            name: "大风",
          };
        default:
          return skycon;
      }
    },
    async init() {
      try {
        let res = await this.$http.get(
          `/proxy/caiyun/weather/${this.parkingLotId}`
        );
        console.log(res);
        if (res.status === "ok") {
          let realtime = res.result.realtime;
          this.quality = realtime.air_quality.description.usa;
          this.temperature = realtime.temperature;
          this.speed = realtime.wind.speed;
          this.humidity = realtime.humidity;
          this.weather = this.weatherChange(realtime.skycon);
          this.weatherTimer = setTimeout(() => {
            this.init();
          }, 1000 * 60 * 30);
        }
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style lang="scss">
#gangting-baseInfo {
  width: 100%;
  height: 6rem;
  box-sizing: border-box;
  padding: 2.06rem 0 2.06rem 2.19rem;
  border-radius: 0.31rem;
  margin-bottom: 0.63rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  & > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  & > .redWarning {
    position: absolute;
    bottom: 0.19rem;
    left: 2.19rem;
    background-color: red;
    color: white;
    padding: 0.19rem 0.38rem;
    font-size: 0.88rem;
    animation: opacityAnimation 1.5s infinite;
  }

  @keyframes opacityAnimation {
    0% {
      opacity: 1;
    }

    50% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  & > div {
    position: relative;
  }

  .remainingParkingSpaces {
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 1rem;
    color: white;

    ul {
      display: flex;
      margin: 0 0.44rem;

      li {
        padding: 0.38rem 0.44rem;
        margin-right: 0.44rem;
        font-size: 1.25rem;
        background-color: rgba(167, 91, 52, 0.31);
        border-radius: 0.31rem;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .revise {
      font-size: 1rem;
      color: white;
      cursor: pointer;
      display: flex;
      align-items: center;

      .reviseIcon {
        margin-left: 1rem;
        width: 2.19rem;
        cursor: pointer;
      }
    }
  }

  .weatherForecast {
    display: flex;
    flex-direction: column;
    align-self: center;

    & > .top {
      display: flex;
      align-content: center;
      padding-bottom: 0.69rem;
      border-bottom: 0.06rem solid rgb(255, 255, 255);
      margin-bottom: 0.25rem;

      .situation {
        display: flex;
        align-content: center;
        margin-right: 3rem;

        & > img {
          width: 2rem;
          height: 2.411rem;
          object-fit: contain;
          margin-right: 0.67rem;
        }

        & > div {
          p {
            color: white;
            font-size: 1.31rem;
            text-align: center;
            font-weight: bold;

            &:last-child {
              font-weight: initial;
              font-size: 0.69rem;
              margin-top: 0.12rem;
            }
          }
        }
      }

      .air {
        font-size: 0.63rem;
        color: white;
        display: flex;
        align-items: center;
        background: hsla(32, 43%, 50%, 0.4);
        border-radius: 1rem 0 0 1rem;
        box-shadow: 0.12rem 0.35rem 3.19rem 0 rgba(34, 96, 136, 0.3);
        padding: 0.5rem 1rem;

        img {
          width: 0.6rem;
          object-fit: contain;
        }

        span {
          margin: 0 0.8rem 0 0.6rem;
        }

        i {
          font-style: initial;
        }
      }
    }

    & > .bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;

      li {
        color: white;
        font-size: 0.63rem;
        display: flex;
        align-items: center;

        img {
          width: 1rem;
        }
      }
    }
  }
}
</style>
